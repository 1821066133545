import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { selectAuthLoginState } from '../../core/auth/auth.selectors';
import { LogService } from '../../core/log/log.service';
import { selectSessionID } from '../../core/session/session.selectors';
import { checkPermissionDialog } from '../../shared/dialog/dn-dialog.actions';
import { onlyWhenAuthenticated, onlyWhenOnline } from '../../shared/util/operators';
import { ErrorMessage } from '../error/error-message.model';
import { manageError } from '../error/error.util';
import { ARTICLE_VIDEO_IDS, TemplateTypeId } from '../template-ct/template-ct.model';
import { selectUserId } from '../user/user.selectors';
import { loadRelated } from '../widget/widget.actions';
import {
	insertComment,
	insertCommentFailure,
	insertCommentSuccess,
	insertMediaAndComment,
	insertMediaAndCommentFailure,
	insertMediaAndCommentSuccess,
	loadComments,
	loadCommentsFailure,
	loadCommentsSuccess,
	loadContent,
	loadContentFailure,
	loadContentList,
	loadContentListByCategory,
	loadContentListByCategoryFailure,
	loadContentListByCategorySuccess,
	loadContentListFailure,
	loadContentListSuccess,
	loadContentSearchList,
	loadContentSearchListFailure,
	loadContentSearchListSuccess,
	loadContentSuccess,
	uploadTemporaryMedia,
	uploadTemporaryMediaFailure,
	uploadTemporaryMediaSuccess
} from './content.actions';
import { Content, DNComment } from './content.model';
import { selectAbilitatoCurrentContent } from './content.selectors';
import { ContentService } from './content.service';

@Injectable({ providedIn: 'root' })
export class ContentEffects {
	loadContent$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadContent),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap((action) => this.logService.info('Effect: loadContent', action)),
			switchMap(({ userId, contentId, template,callbackUrl }) =>
				this.contentService.loadContentById(userId, contentId, template.templateDescription).pipe(
					tap((content: Content) =>
						this.logService.infoDebug('Ho ricevuto i dati content: ', undefined, content)
					),
					map((datiContent: Content) => loadContentSuccess({ datiContent, template, userId, callbackUrl })),
					catchError((error: ErrorMessage) => of(loadContentFailure({ error })))
				)
			)
		)
	);

	loadContentSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadContentSuccess),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap((action) => this.logService.info('Effect: loadContentSuccess', action)),
			// If templateId refers to article or video, fetch relatedContents
			map(({ datiContent, template, userId, callbackUrl }) =>
				checkPermissionDialog({
					templateVisibility: template.visibilita,
					callbackUrl: callbackUrl,
					selectorAbilitato: this.store.select(selectAbilitatoCurrentContent)
				})
			)
		)
	);

	insertComment$ = createEffect(() =>
		this.actions$.pipe(
			ofType(insertComment),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap(({ parentId }) => this.logService.debug('Effect: insertComment to ', parentId)),
			concatLatestFrom(() => [this.store.select(selectSessionID)]),
			switchMap(
				([
					{
						parentId,
						parentTemplate,
						title,
						text,
						authorId,
						commentTemplate,
						attachmentId,
						attachmentTemplate
					},
					sessionId
				]) =>
					this.contentService
						.insertComment(
							parentId,
							parentTemplate.templateDescription,
							title,
							text,
							authorId,
							attachmentId || 0,
							attachmentTemplate?.templateID || 0,
							commentTemplate?.templateDescription,
							sessionId
						)
						.pipe(
							tap((replyTree) =>
								this.logService.infoDebug('Effect: insertComment done', undefined, replyTree)
							),
							map((comments: DNComment[]) => comments.map((comment) => ({ ...comment, parentId }))),
							map((replyTree: DNComment[]) => insertCommentSuccess({ comments: replyTree })),
							catchError((error: ErrorMessage) => of(insertCommentFailure({ error })))
						)
			)
		)
	);

	loadComments$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadComments),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap(({ commentId }) => this.logService.debug('Effect: loadComments ', commentId)),
			mergeMap(({ commentId, userId, template }) =>
				this.contentService
					.loadCommentsByContentId(userId, commentId, template.templateDescription)
					.pipe(
						tap((comments: DNComment[]) =>
							this.logService.infoDebug(
								'Effect: loadComments data received --> ',
								undefined,
								comments
							)
						),
						map((comments: DNComment[]) =>
							comments.map((comment) => ({ ...comment, parentId: commentId }))
						),
						map((comments: DNComment[]) => loadCommentsSuccess({ comments })),
						catchError((error: ErrorMessage) => of(loadCommentsFailure({ error })))
					)
			)
		)
	);

	loadContentList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadContentList),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap(() => this.logService.debug('Effect: loadContentList')),
			switchMap((action) =>
				this.contentService
					.loadContentList(
						action.template,
						action.userId,
						action.fromWhere,
						action.howMany,
						action.premium
					)
					.pipe(
						tap((contentListData: Content[]) =>
							this.logService.infoDebug(
								'Ho ricevuto i dati della lista: ',
								undefined,
								contentListData
							)
						),
						map((contentListData: Content[]) => loadContentListSuccess({ contentListData })),
						catchError((error: ErrorMessage) => of(loadContentListFailure({ error })))
					)
			)
		)
	);

	loadContentListByCategory$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadContentListByCategory),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap(() => this.logService.debug('Effect: loadContentListByCategory')),
			switchMap((action) =>
				this.contentService
					.loadContentListByCategory(
						action.categoryId,
						action.userId,
						action.fromWhere,
						action.howMany,
						action.premium
					)
					.pipe(
						tap((contentListData: Content[]) =>
							this.logService.infoDebug(
								'Ho ricevuto i dati della lista: ',
								undefined,
								contentListData
							)
						),
						map((contentListData: Content[]) =>
							loadContentListByCategorySuccess({ contentListData })
						),
						catchError((error: ErrorMessage) => of(loadContentListByCategoryFailure({ error })))
					)
			)
		)
	);

	loadContentSearchList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadContentSearchList),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap(() => this.logService.debug('Effect: loadContentSearchList')),
			exhaustMap((action) =>
				this.contentService
					.loadContentSearchList(action.userId, action.searchKey, action.fromWhere, action.howMany)
					.pipe(
						tap((contentSearchListData: Content[]) =>
							this.logService.infoDebug(
								'Ho ricevuto i dati della Search lista: ',
								undefined,
								contentSearchListData
							)
						),
						map((contentSearchListData: Content[]) =>
							loadContentSearchListSuccess({ contentSearchListData })
						),
						catchError((error: ErrorMessage) => of(loadContentSearchListFailure({ error })))
					)
			)
		)
	);

	//  load failure
	loadFailures$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadContentFailure, loadCommentsFailure, uploadTemporaryMediaFailure),
			concatLatestFrom(() => this.store.select(selectAuthLoginState)),
			tap(([payload, _]) => this.logService.info('Effect: loadContentFailure', payload.error)),
			map(([{ error }, authLoginState]) => manageError(error, authLoginState))
		)
	);

	// Media
	uploadTemporaryMedia$ = createEffect(() =>
		this.actions$.pipe(
			ofType(uploadTemporaryMedia),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap((action) => this.logService.info('Effect: uploadTemporaryMedia', action)),
			concatLatestFrom(() => [this.store.select(selectUserId)]),
			switchMap(([{ media }, userId]) =>
				this.contentService.uploadTemporaryMedia(userId, media).pipe(
					tap((response: string) =>
						this.logService.infoDebug('Effect: uploadTemporaryMedia data received: ', response)
					),
					map((mediaTemporaryName: string) => uploadTemporaryMediaSuccess({ mediaTemporaryName })),
					catchError((error) => of(uploadTemporaryMediaFailure({ error })))
				)
			)
		)
	);

	insertMediaAndComment$ = createEffect(() =>
		this.actions$.pipe(
			ofType(insertMediaAndComment),
			onlyWhenOnline(this.store),
			onlyWhenAuthenticated(this.store),
			tap((action) => this.logService.info('Effect: insertMedia', action)),
			concatLatestFrom(() => this.store.select(selectSessionID)),
			switchMap(
				([
					{
						commentTemplate,
						fileName,
						authorId,
						parentId,
						parentTemplate,
						text,
						title,
						attachmentTemplate
					},
					clientSessionId
				]) =>
					this.contentService
						.insertMediaAndComment(
							parentId,
							parentTemplate.templateDescription,
							title,
							text,
							commentTemplate.templateDescription,
							clientSessionId,
							authorId,
							attachmentTemplate.templateID,
							attachmentTemplate.contentTypeID,
							fileName,
							attachmentTemplate.templateDescription
						)
						.pipe(
							tap((response: DNComment[]) =>
								this.logService.infoDebug('Effect: insertMedia data received: ', response)
							),
							map((comments: DNComment[]) => comments.map((comment) => ({ ...comment, parentId }))),
							map((replyTree: DNComment[]) =>
								insertMediaAndCommentSuccess({ comments: replyTree })
							),
							catchError((error) => of(insertMediaAndCommentFailure({ error })))
						)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private logService: LogService,
		private contentService: ContentService,
		private store: Store
	) {}
}
